html, body {
    padding: 0;
    margin: 0;
}

/** FIXME: 하나의 역할만 할 것!!! */
.centered-text {
    text-align: center;
}

.why-acher-text {
    padding: 87px 0 58px;
}

.new-acher-text {
    padding: 125px 0 60px;
}

.easy-acher-text {
    padding: 112px 0 58px;
}

.new-auth-text {
    padding: 120px 0 60px;
}

.disable-selection {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


.acher-start-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
