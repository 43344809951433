@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700;900&display=swap);
body {
    margin: 0;
    font-family: 'Noto Sans CJK KR', 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,body{padding:0;margin:0}.centered-text{text-align:center}.why-acher-text{padding:87px 0 58px}.new-acher-text{padding:125px 0 60px}.easy-acher-text{padding:112px 0 58px}.new-auth-text{padding:120px 0 60px}.disable-selection{-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.acher-start-box{display:flex;flex-direction:row;align-items:center;justify-content:center}

